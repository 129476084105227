<template>
  <div
    class="pa-3"
    style="width: 100%"
  >
    <div
      layout="row"
      layout-align="start start"
      class="mt-3 index-header"
    >
      <div class="title mt-1">
        Sessions
      </div>
      <v-select
        v-model="mode"
        v-focus
        :items="filters"
        hide-details
        single-line
        class="mt-0 pt-0 ml-4 select-mode"
        style="flex: unset"
        @input="onSelectMode"
      />
      <v-select
        v-model="params.application"
        v-focus
        :items="applications"
        hide-details
        single-line
        label="Application"
        class="mt-0 ml-4 pt-0 select-mode"
        @input="onSelectApp"
      />
      <span flex />
      <v-btn
        icon
        title="Print"
        @click="print"
      >
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-btn
        icon
        title="Export"
        @click="exportCSV"
      >
        <v-icon>mdi-cloud-download</v-icon>
      </v-btn>
      <div
        layout="column"
        layout-align="center end"
      >
        <v-text-field
          v-model="keywords"
          placeholder="Quick search..."
          clearable
          outline
          single-line
          hide-details
          class="small-text-field search-input"
          @click:clear="onKeywordsClear"
        />
        <a
          href="#"
          class="caption grey--text"
          @click.prevent="toggleAdvancedSearch"
        >Advanced search</a>
      </div>
    </div>

    <div
      v-show="advancedSearch"
      class="index-header"
    >
      <v-layout
        row
        wrap
        class="px-2"
      >
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-text-field
            v-model="params.start_text"
            label="Start date"
            outline
            hide-details
            @blur="params.start = parseDate(params.start_text)"
            @change="fetch(false, true)"
          >
            <div slot="prepend-inner">
              <v-menu
                ref="searchStart"
                :close-on-content-click="false"
                :return-value.sync="params.start"
                transition="scale-transition"
                offset-y
                full-width
                lazy
              >
                <v-icon slot="activator">
                  event
                </v-icon>
                <v-date-picker
                  v-model="params.start"
                  first-day-of-week="1"
                  @change="fetch(false, true)"
                  @input="$refs.searchStart.save(params.start); params.start_text = americanizeDate(params.start); updateEnd()"
                />
              </v-menu>
            </div>
          </v-text-field>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-text-field
            v-model="params.end_text"
            label="End date"
            outline
            hide-details
            @blur="params.end = parseDate(params.end_text)"
            @change="fetch(false, true)"
          >
            <div slot="prepend-inner">
              <v-menu
                ref="searchEnd"
                :close-on-content-click="false"
                :return-value.sync="params.end"
                transition="scale-transition"
                offset-y
                full-width
              >
                <v-icon slot="activator">
                  event
                </v-icon>
                <v-date-picker
                  ref="endPicker"
                  v-model="params.end"
                  first-day-of-week="1"
                  @change="fetch(false, true)"
                  @input="$refs.searchEnd.save(params.end); params.end_text = americanizeDate(params.end)"
                />
              </v-menu>
            </div>
          </v-text-field>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-text-field
            v-model="params.last_text"
            label="Last modification"
            outline
            hide-details
            @blur="params.last = parseDate(params.last_text)"
            @change="fetch(false, true)"
          >
            <div slot="prepend-inner">
              <v-menu
                ref="searchLast"
                :close-on-content-click="false"
                :return-value.sync="params.last"
                transition="scale-transition"
                offset-y
                full-width
                lazy
              >
                <v-icon slot="activator">
                  event
                </v-icon>
                <v-date-picker
                  v-model="params.last"
                  first-day-of-week="1"
                  @input="$refs.searchLast.save(params.last); params.last_text = americanizeDate(params.last)"
                  @change="fetch(false, true)"
                />
              </v-menu>
            </div>
          </v-text-field>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-select
            v-model="params.langs"
            v-focus
            label="Languages"
            hide-details
            multiple
            outline
            :items="langs"
            item-value="id"
            item-text="name"
            dense
            @change="fetch(false, true)"
          >
            <template
              slot="selection"
              slot-scope="{ index }"
            >
              <span
                v-if="index === 0"
                class="grey--text caption"
              >{{ params.langs.length }} selected</span>
            </template>
          </v-select>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-text-field
            v-model="params.school"
            label="School / Class / Extra"
            outline
            hide-details
            @input="fetch(false, true)"
          />
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-text-field
            v-model="params.contact"
            label="Contacts"
            outline
            hide-details
            @input="fetch(false, true)"
          />
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-text-field
            v-model="params.address"
            label="Address"
            outline
            hide-details
            @input="fetch(false, true)"
          />
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-select
            v-model="params.team_id"
            v-focus
            label="Team"
            hide-details
            multiple
            outline
            :items="teams"
            item-value="id"
            item-text="name"
            dense
            @change="fetch(false, true)"
          >
            <template
              slot="selection"
              slot-scope="{ index }"
            >
              <span
                v-if="index === 0"
                class="grey--text caption"
              >{{ params.team_id.length }} selected</span>
            </template>
          </v-select>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-text-field
            v-model="params.event"
            label="Event name"
            outline
            hide-details
            @input="fetch(false, true)"
          />
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-select
            v-model="params.status"
            v-focus
            label="Status"
            hide-details
            multiple
            outline
            :items="status"
            dense
            @change="fetch(false, true)"
          >
            <template
              slot="selection"
              slot-scope="{ index }"
            >
              <span
                v-if="index === 0"
                class="grey--text caption"
              >{{ params.status.length }} selected</span>
            </template>
          </v-select>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-select
            v-model="params.target_group_id"
            v-focus
            label="Target group"
            hide-details
            multiple
            outline
            :items="targetGroups"
            item-value="id"
            item-text="name"
            dense
            @change="fetch(false, true)"
          >
            <template
              slot="selection"
              slot-scope="{ index }"
            >
              <span
                v-if="index === 0"
                class="grey--text caption"
              >{{ params.target_group_id.length }} selected</span>
            </template>
          </v-select>
        </v-flex>
        <v-flex
          v-if="!isTrainer"
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-autocomplete
            v-model="params.trainers"
            v-focus
            label="Trainers"
            hide-details
            multiple
            outline
            :items="trainers"
            item-value="id"
            item-text="fullname"
            dense
            @change="fetch(false, true)"
          >
            <template
              slot="selection"
              slot-scope="{ index }"
            >
              <span
                v-if="index === 0"
                class="grey--text caption"
              >{{ params.trainers.length }} selected</span>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex
          v-if="isCoord || isAdmin"
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-autocomplete
            v-model="params.visitors"
            v-focus
            label="Visitors"
            hide-details
            multiple
            outline
            :items="visitors"
            item-value="id"
            item-text="fullname"
            dense
            @change="fetch(false, true)"
          >
            <template
              slot="selection"
              slot-scope="{ index }"
            >
              <span
                v-if="index === 0"
                class="grey--text caption"
              >{{ params.visitors.length }} selected</span>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-select
            v-model="params.session_type"
            v-focus
            label="Session type"
            hide-details
            multiple
            outline
            small-chips
            deletable-chips
            :items="sessionTypes"
            dense
            @change="fetch(false, true)"
          >
            <template
              slot="selection"
              slot-scope="{ index }"
            >
              <span
                v-if="index === 0"
                class="grey--text caption"
              >{{ params.session_type.length }} selected</span>
            </template>
          </v-select>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-autocomplete
            v-model="params.topic_id"
            v-focus
            label="Concept"
            hide-details
            multiple
            outline
            small-chips
            deletable-chips
            :items="topics"
            item-value="id"
            item-text="name"
            dense
            @change="fetch(false, true)"
          >
            <template
              slot="selection"
              slot-scope="{ index }"
            >
              <span
                v-if="index === 0"
                class="grey--text caption"
              >{{ params.topic_id.length }} selected</span>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-select
            v-model="params.school_type_id"
            v-focus
            label="School type"
            hide-details
            multiple
            outline
            small-chips
            deletable-chips
            :items="schoolTypes"
            item-value="id"
            item-text="name"
            dense
            @change="fetch(false, true)"
          >
            <template
              slot="selection"
              slot-scope="{ index }"
            >
              <span
                v-if="index === 0"
                class="grey--text caption"
              >{{ params.school_type_id.length }} selected</span>
            </template>
          </v-select>
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-text-field
            v-model="params.comments"
            label="Comments"
            outline
            hide-details
            @input="fetch(false, true)"
          />
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-text-field
            v-model="params.ticket_number"
            label="Ticket number"
            outline
            hide-details
            @input="fetch(false, true)"
          />
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-checkbox
            v-model="params.not_fully_eval"
            hide-details
            label="Not fully evaluated by trainers"
            @change="fetch(false, true)"
          />
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-checkbox
            v-model="params.is_online"
            hide-details
            label="Is online"
            @change="fetch(false, true)"
          />
        </v-flex>
        <v-flex
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-checkbox
            v-model="params.is_inclusive"
            hide-details
            label="Is inclusive"
            @change="fetch(false, true)"
          />
        </v-flex>
        <v-flex
          v-if="isTrainer"
          xs12
          sm6
          md3
          class="px-2 pb-2"
        >
          <v-checkbox
            v-model="params.onlyMySessions"
            hide-details
            label="Only my sessions"
            @change="fetch(false, true)"
          />
        </v-flex>
        <v-flex
          xs12
          sm12
          md12
          class="px-2 pb-2"
        >
          <div
            layout="row"
            layout-align="end center"
            style="height: 100%"
          >
            <v-btn
              color="error"
              class="ma-0"
              @click="onSelectMode(true)"
            >
              <v-icon left>
                mdi-autorenew
              </v-icon> Reset
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <v-divider
      v-show="advancedSearch"
      class="index-header"
    />
    <div
      v-if="advancedSearch"
      class="toolbar-toggle index-header"
      @click="toggleAdvancedSearch"
    >
      <v-icon size="18px">
        mdi-chevron-up
      </v-icon>
    </div>
    <div
      v-if="isCoord || isAdmin"
      layout="row"
      class="index-header"
    >
      <v-menu
        v-model="sessionForm"
        offset-y
        :close-on-content-click="false"
      >
        <v-btn
          slot="activator"
          style="margin-left: 0"
          depressed
          color="primary"
        >
          <v-icon left>
            mdi-plus-box
          </v-icon>
          Add a session
          <v-icon right>
            mdi-chevron-down
          </v-icon>
        </v-btn>
        <v-list v-if="getDefaultApp() == Consts.APPLICATION.BEESECURE">
          <v-list-tile
            v-for="t in sessionTypesLong"
            :key="t.value"
            :to="{name: 'sessions-edit', params: {id: 'new'}, query:{type: t.value}}"
          >
            {{ t.text }}
          </v-list-tile>
        </v-list>
        <v-card
          v-else
          width="600px"
          @keydown.tab.stop="() => {}"
        >
          <v-card-title
            layout="column"
            layout-align="start start"
            class="pb-0"
          >
            <div
              layout="row"
              layout-align="start center"
              class="ml-1 mr-1"
              style="width: 100%"
            >
              <div class="title">
                <b>Add a session</b>
              </div>
              <div
                layout="column"
                flex
                class="full-width"
              >
                <div
                  layout="row"
                  layout-align="center end"
                >
                  <span flex />
                  <v-btn
                    icon
                    class="mr-1"
                    @click="sessionForm = false"
                  >
                    <v-icon color="red">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card-title>
          <v-card-text @keydown.enter="newSession.application && newSession.session_type ? $router.push({name: 'sessions-edit', params: {id: 'new'}, query:{type: newSession.session_type, application: newSession.application}}) : ''">
            <div
              layout="row"
              class="full-width"
            >
              <div
                v-if="getDefaultApp() === Consts.APPLICATION.ALL"
                layout="column"
                class="pr-1"
                flex="50"
              >
                <v-select
                  slot="input"
                  v-model="newSession.application"
                  v-focus
                  class="full-width"
                  outline
                  label="Application"
                  :items="apps"
                />
              </div>
              <div
                layout="column"
                class="pl-1"
                flex="50"
              >
                <v-select
                  slot="input"
                  v-model="newSession.session_type"
                  v-focus
                  class="full-width"
                  :disabled="!newSession.application"
                  outline
                  label="Session type"
                  :items="sessionTypesLong"
                />
              </div>
            </div>
            <template v-if="newSession.session_type == Consts.ACTIVITY">
              <div
                layout="row"
                class="full-width"
              >
                <v-radio-group
                  v-model="newSession.is_package"
                  row
                  class="mt-0"
                >
                  <v-radio
                    :value="0"
                    label="For a concept"
                  />
                  <v-radio
                    :value="1"
                    label="Or a package"
                  />
                </v-radio-group>
              </div>
              <div
                v-if="newSession.is_package"
                layout="row"
                class="full-width"
              >
                <div
                  layout="column"
                  class="pr-1"
                  flex="50"
                >
                  <v-select
                    slot="input"
                    v-model="newSession.package_id"
                    v-focus
                    class="full-width"
                    :disabled="!newSession.is_package"
                    outline
                    label="Package"
                    :items="packages"
                    item-text="name"
                    item-value="id"
                  />
                </div>
                <div
                  layout="column"
                  class="pl-1"
                  flex="50"
                >
                  <v-text-field
                    v-model="newSession.start_text"
                    label="Start date"
                    outline
                    hide-details
                    @blur="newSession.start_date = parseDate(newSession.start_text)"
                  >
                    <div slot="prepend-inner">
                      <v-menu
                        ref="newStart"
                        :close-on-content-click="false"
                        :return-value.sync="newSession.start_date"
                        transition="scale-transition"
                        offset-y
                        full-width
                        lazy
                      >
                        <v-icon slot="activator">
                          event
                        </v-icon>
                        <v-date-picker
                          v-model="newSession.start_date"
                          first-day-of-week="1"
                          @input="$refs.newStart.save(newSession.start_date); newSession.start_text = americanizeDate(newSession.start_date)"
                        />
                      </v-menu>
                    </div>
                  </v-text-field>
                </div>
              </div>
            </template>
          </v-card-text>
          <v-card-actions
            layout-align="start center"
          >
            <span flex />
            <template v-if="newSession.application && newSession.session_type && (!newSession.is_package || newSession.package_id)">
              <v-btn
                v-if="newSession.session_type == Consts.ACTIVITY && newSession.is_package"
                flat
                @click="createPackage"
              >
                Continue
                <v-icon
                  color="primary"
                  right
                >
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                flat
                :to="{name: 'sessions-edit', params: {id: 'new'}, query:{type: newSession.session_type, application: newSession.application}}"
              >
                Continue
                <v-icon
                  color="primary"
                  right
                >
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </template>
            <v-btn
              v-else
              flat
              disabled
            >
              Continue
              <v-icon
                color="primary"
                right
              >
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-btn
        depressed
        color="primary"
        :to="{name: 'sessions-add-multi'}"
      >
        <v-icon left>
          mdi-plus-box-multiple
        </v-icon>
        Add multiple sessions
      </v-btn>
    </div>

    <v-layout child-flex>
      <div
        v-if="loading"
        layout="row"
        class="ma-5 text-xs-center full-width"
      >
        <v-progress-circular
          indeterminate
          :size="32"
          color="accent"
          class="ma-auto"
        />
      </div>
      <div
        v-else
        class="sessions-table"
      >
        <div class="v-table__overflow">
          <table class="v-datatable v-table theme--light">
            <thead>
              <tr>
                <th
                  v-for="header in headers"
                  :key="header.text"
                  :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '', header.noPrintable ? 'no-printable' : '', header.class ? header.class : '']"
                  :width="header.width ? header.width : ''"
                  @click="changeSort(header.value)"
                >
                  <v-icon
                    small
                    class="no-printable"
                  >
                    arrow_upward
                  </v-icon>
                  <span style="vertical-align: baseline">
                    {{ header.text }}
                  </span>
                  <span style="vertical-align: super;">
                    <v-icon
                      v-for="(i,k) in header.icons"
                      :key="'icon'+i+k"
                      class="no-printable"
                      left
                      size="10"
                      style="opacity: unset; color: #bfbfbf"
                    >mdi-{{ i }}</v-icon>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in sessions"
                :key="'session_'+ item.id + index"
                :class="{'background-draft background-draft-hover': item.status === Consts.DRAFT, 'background-onhold background-onhold-hover': item.status === Consts.ONHOLD}"
              >
                <td class="text-xs-center">
                  <v-icon
                    color="black"
                    class="mr-1"
                    small
                  >
                    <template v-if="item.application === Consts.APPLICATION.BEESECURE">
                      icon-bee48
                    </template>
                    <template v-else>
                      icon-kayak48
                    </template>
                  </v-icon>
                  <router-link
                    class="black--text"
                    :to="{name: 'sessions-show-info', params: {id: item.id}}"
                  >
                    {{ item.reference }}
                  </router-link>
                  <span class="printable text-xs-center full-width">{{ sessionTypeStr(item.session_type) }}</span>
                </td>
                <td class="text-xs-center no-printable">
                  <v-chip
                    :color="sessionTypeColor(item.session_type)"
                    class="lighten-3 no-printable"
                    small
                  >
                    {{ sessionTypeStr(item.session_type) }}
                  </v-chip>
                  <v-avatar
                    :title="sessionTypeChar(item.session_type)"
                    :color="sessionTypeColor(item.session_type)"
                    :size="32"
                    class="white--text font-weight-medium printable-avatar"
                  >
                    {{ sessionTypeChar(item.session_type) }}
                  </v-avatar>
                </td>
                <td class="pl-1">
                  <div layout="row">
                    <div
                      v-if="isTrainer && (!isCandidate(item) || isFreelancesObj(item)) && (item.status == Consts.RELEASE || item.status == Consts.TOVALIDATE || item.status == Consts.BLOCKED || item.status == Consts.TOEVALUATE)"
                      layout="column"
                      class="no-printable clickable"
                    >
                      <div
                        v-for="(p,i) in item.presences"
                        :key="'pres2_' + item.id + i"
                        layout="row"
                        layout-align="start center"
                        class="dates-row"
                      >
                        <v-checkbox
                          v-model="p.is"
                          style="padding: 0"
                          hide-details
                          :disabled="loadingPresences || !canTrain(item, p)"
                          @change="setPresence(item)"
                        />
                      </div>
                    </div>
                    <div v-else-if="isTrainer">
                      <v-checkbox
                        style="padding: 0"
                        hide-details
                        :disabled="true"
                      />
                    </div>
                    <div
                      :id="item.id"
                      layout="column"
                      class="no-printable clickable"
                      @click="openDates($event, item);"
                    >
                      <div
                        v-for="(p,i) in item.presences"
                        :key="'pres_' + item.id + i"
                        layout="row"
                        layout-align="start center"
                        class="dates-row"
                        fill-height
                        layout-wrap
                      >
                        <span style="width: 100px">{{ formatDayHuman(p.start) }}</span>
                        {{ formatTime(p.start) }}-{{ formatTime(p.end) }}
                      </div>
                    </div>
                    <div
                      layout="row"
                      class="printable"
                    >
                      <div
                        v-for="d in item.dates"
                        :key="'date_' + item.id + d.id"
                      >
                        {{ formatDayHuman(d.start) }}
                        {{ formatTime(d.start) }}-{{ formatTime(d.end) }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="pl-1">
                  <div
                    layout="column"
                    class="clickable"
                    @click="!isTraining(item) ? openOrganization($event, item) : ''"
                  >
                    <template v-if="!isTraining(item)">
                      <div
                        v-if="item.organization"
                        layout="row"
                        layout-align="start center"
                      >
                        <span class="bold-print">{{ item.organization ? item.organization.name : '' }}</span>
                      </div>
                      <div
                        v-else
                        layout="row"
                        layout-align="center center"
                      >
                        -
                      </div>
                      <div
                        v-if="item.organization"
                        class="printable"
                      >
                        {{ item.organization.number }} {{ item.organization.street }}
                      </div>
                      <div
                        v-if="item.organization"
                        class="printable"
                      >
                        {{ item.organization.zip_code }} {{ item.organization.city }}
                      </div>
                      <div
                        v-if="item.organization"
                        class="printable"
                      >
                        {{ item.organization.phone }}
                      </div>
                      <div
                        v-if="item.organization"
                        class="printable"
                      >
                        {{ item.organization.email }}
                      </div>
                    </template>
                    <div
                      v-if="item.team"
                      layout="row"
                    >
                      {{ item.team.name }}
                    </div>
                  </div>
                </td>
                <td class="pl-1">
                  <div layout="row">
                    <div
                      layout="column"
                      class="clickable"
                      @dblclick="onClassDblClick($event, item)"
                      @click="openClass($event, item)"
                    >
                      <div
                        layout="row"
                        layout-align="start center"
                      >
                        <span
                          v-if="isFreelancesObj(item) && item.location"
                          class="mr-1"
                        >
                          {{ item.location }}
                        </span>
                        <span
                          v-if="item.class_name || item.room"
                          class="mr-1"
                        >
                          {{ item.class_name }}
                          <template v-if="item.class_name && item.room"> / </template>
                          {{ item.room }}
                        </span>
                        <span
                          v-if="item.lang_id"
                          class="font-weight-bold text-uppercase"
                        >[{{ item.lang_id }}]</span>
                        &nbsp;
                        <template v-if="!isEvent(item) && !isInternal(item) && !isFreelancesObj(item)">
                          <v-icon v-if="!item.beamer">
                            mdi-projector
                          </v-icon>
                          <v-icon v-if="!item.screen">
                            mdi-projector-screen
                          </v-icon>
                          <v-icon v-if="!item.loudspeak">
                            mdi-speaker-wireless
                          </v-icon>
                          <v-icon v-if="!item.internet">
                            mdi-web
                          </v-icon>
                          <v-icon v-if="!item.socket">
                            mdi-power-plug
                          </v-icon>
                          <v-icon v-if="!item.usb">
                            mdi-usb
                          </v-icon>
                        </template>
                      </div>
                      <div
                        layout="row"
                        class="printable"
                      >
                        {{ item.referent_fullname }} {{ item.referent_phone }}
                      </div>
                      <div
                        v-if="item.comments"
                        layout="row"
                        class="printable dont-break-out"
                      >
                        <b>{{ item.comments }}</b>
                      </div>
                    </div>
                    <span flex />
                    <div
                      style="width: 24px"
                      class="no-printable"
                    >
                      <comment-icon :item="item" />
                    </div>
                    <div
                      style="width: 24px"
                      class="no-printable"
                    >
                      <special-training-icon :item="item" />
                    </div>
                  </div>
                </td>
                <td class="px-1">
                  <div
                    layout="row"
                    layout-align="start center"
                  >
                    {{ item.topic ? item.topic.name : '-' }}
                    <span flex />
                    <v-icon
                      v-if="item.topic && item.topic.color"
                      :color="item.topic.color"
                      class="ml-1"
                    >
                      mdi-circle
                    </v-icon>
                    <v-icon
                      v-if="item.is_inclusive"
                      title="Is inclusive"
                      color="primary"
                      class="ml-1"
                    >
                      mdi-all-inclusive
                    </v-icon>
                    <v-icon
                      v-if="item.is_online"
                      title="Is online"
                      color="primary"
                      class="ml-1"
                    >
                      mdi-cast-education
                    </v-icon>
                  </div>
                </td>
                <td class="no-printable pl-1">
                  <div
                    layout="column"
                    class="clickable"
                    @click="openTG($event, item)"
                    @dblclick="onTgDblClick($event, item)"
                  >
                    <template v-if="isInternal(item)">
                      <template v-if="item.target_group">
                        {{ item.target_group.name }}
                      </template>
                      <template v-else>
                        {{ getInternalType(item.internal_type) }}
                      </template>
                    </template>
                    <template v-else>
                      {{ item.target_group ? item.target_group.name : '-' }}
                    </template>
                  </div>
                </td>
                <td class="text-xs-center">
                  <div
                    layout="column"
                    class="clickable dont-break-out"
                    @click="openPupils($event, item)"
                    @dblclick="onPupilsDblClick($event, item)"
                  >
                    {{ (item.session_type == Consts.INTERNAL) ? '-' : item.nb_participants }}
                  </div>
                </td>
                <td class="pl-1">
                  <div
                    v-if="item.contact || item.contact2"
                    layout="column"
                    class="clickable"
                    @click="openContact($event, item)"
                    @dblclick="onContactDblClick($event, item)"
                  >
                    <div
                      v-if="item.contact"
                      layout="column"
                      class="dont-break-out"
                    >
                      {{ item.contact.firstname }} {{ item.contact.lastname }}
                      <div
                        v-if="item.contact.phone"
                        layout="row"
                        class="printable"
                      >
                        {{ item.contact.phone }}
                      </div>
                      <div
                        v-if="item.contact.email"
                        layout="row"
                        class="printable"
                      >
                        <a href="#">{{ item.contact.email }}</a>
                      </div>
                    </div>
                    <div
                      v-if="item.contact2"
                      layout="column"
                      class="printable dont-break-out"
                    >
                      {{ item.contact2.firstname }} {{ item.contact2.lastname }}
                      <div
                        v-if="item.contact2.phone"
                        layout="row"
                      >
                        {{ item.contact2.phone }}
                      </div>
                      <div
                        v-if="item.contact2.email"
                        layout="row"
                      >
                        <a href="#">{{ item.contact2.email }}</a>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    layout="row"
                    layout-align="start center"
                  >
                    -
                  </div>
                </td>
                <td class="pl-1 no-printable">
                  <div v-if="!item.trainers.length && !item.nb_trainers">
                    -
                  </div>
                  <div v-else-if="item.trainers && item.trainers.length">
                    <div
                      v-for="t in item.trainers"
                      :key="'trainer_' + item.id + t.id"
                    >
                      {{ t.firstname[0] }}. {{ t.lastname }}
                      <span
                        v-if="isCoord || isAdmin"
                        class="ml-1"
                      >[{{ t.nb_sessions_month }} | {{ t.nb_sessions_year }}]</span>
                      <div v-else-if="item.nb_trainers">
                        + {{ item.nb_trainers }}
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    {{ item.nb_trainers }}
                  </div>
                  <template v-if="(isCoord || isAdmin || isTraining(item)) && item.session_visitor && item.session_visitor.length">
                    <v-divider />
                    <div
                      v-for="t in item.session_visitor"
                      :key="'visitor_' + item.id + t.user_id"
                      layout="row"
                      layout-align="start center"
                    >
                      <v-icon
                        v-if="isQueued(t.user_id, item, item.session_visitor)"
                        class="mr-1"
                        small
                        title="In the queue"
                      >
                        mdi-human-queue
                      </v-icon>
                      <span>({{ t.user.firstname[0] }}. {{ t.user.lastname }})</span>
                    </div>
                  </template>
                </td>
                <td class="no-printable text-xs-center">
                  {{ formatDate(item.updated_at ? item.updated_at : item.created_at) }}
                </td>
                <td class="text-xs-center">
                  <v-avatar
                    :title="sessionStatusStrLong(item.status)"
                    :color="sessionStatusColor(item.status)"
                    :size="32"
                    class="black--text font-weight-medium"
                  >
                    {{ sessionStatusStr(item.status) }}
                  </v-avatar>
                </td>
                <td class="no-printable">
                  <v-btn
                    v-if="(isAdmin || isAppCoord(item)) && (item.status != 3 && item.status != 4)"
                    title="Upgrade"
                    class="ma-0"
                    icon
                    small
                    :disabled="!isUpgradable(item.status, item)"
                    @click="openUpgrade($event, item)"
                  >
                    <v-icon
                      color="success"
                      :size="iconSize"
                    >
                      mdi-check
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-else-if="(isAdmin || isAppCoord(item)) && item.status == 3"
                    title="Upgrade"
                    class="ma-0"
                    icon
                    small
                    :disabled="!isUpgradable(item.status, item)"
                    @click="blockSession(item)"
                  >
                    <v-icon
                      color="success"
                      :size="iconSize"
                    >
                      mdi-check
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-else-if="(isAdmin || isAppCoord(item)) && item.status == 4"
                    title="Upgrade"
                    class="ma-0"
                    icon
                    small
                    :disabled="!isUpgradable(item.status, item)"
                    @click="evalSession(item)"
                  >
                    <v-icon
                      color="success"
                      :size="iconSize"
                    >
                      mdi-check
                    </v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="!canBeTeacherEval(item) && !canBeTrainerEval(item)"
                    title="Evaluate"
                    class="ma-0"
                    icon
                    small
                    @click="evaluate(item)"
                  >
                    <div layout="row">
                      <div :class="{'eval-block': true, 'primary': item.is_fully_eval}" />
                      <div
                        v-if="!isInternal(item)"
                        :class="{'eval-block': true, 'primary': item.teachers_evaluations && item.teachers_evaluations.length}"
                      />
                    </div>
                  </v-btn>
                  <v-btn
                    v-if="isTrainer && (!isCandidate(item) || isFreelancesObj(item))"
                    title="Join the session"
                    class="ma-0"
                    icon
                    small
                    :disabled="!canDeliverSess(item)"
                    @click="joinSession(item)"
                  >
                    <v-icon
                      :color="isAlreadyTrainer(item) ? 'orange' : 'primary'"
                      :size="iconSize"
                    >
                      mdi-account-{{ isAlreadyTrainer(item) ? 'minus' : 'plus' }}
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="canParticipate(item)"
                    title="Participate"
                    class="ma-0"
                    icon
                    small
                    @click="participate(item)"
                  >
                    <v-icon
                      color="primary"
                      :size="iconSize"
                    >
                      mdi-account-plus
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="canUnparticipate(item)"
                    title="Remove participation"
                    class="ma-0"
                    icon
                    small
                    @click="participate(item)"
                  >
                    <v-icon
                      color="orange"
                      :size="iconSize"
                    >
                      mdi-account-minus
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!isInternal(item) && (isAdmin || isAppCoord(item))"
                    class="ma-0"
                    icon
                    small
                    @click="duplicate(item.id)"
                  >
                    <v-icon
                      title="Duplicate"
                      :size="iconSize"
                    >
                      mdi-content-duplicate
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </v-layout>

    <!-- V-MENUS -->
    <!-- Datee -->
    <v-menu
      v-model="datesMenus[item.id]"
      lazy
      :close-on-click="datesCloseMenus[item.id]"
      :close-on-content-click="false"
      offset-y
      offset-x
      :position-x="item.x"
      :position-y="item.y"
      absolute
      bottom
      right
      z-index="20"
    >
      <v-card
        style="width: 600px"
        @keydown.tab.stop="() => {}"
      >
        <v-card-title
          layout="column"
          layout-align="start start"
          class="pb-0"
        >
          <div
            layout="row"
            layout-align="start center"
            class="ml-1 mr-1 full-width"
            flex
            style="width: 100%"
          >
            <v-avatar
              :title="sessionStatusStrLong(item.status)"
              :color="sessionStatusColor(item.status)"
              :size="32"
              class="black--text font-weight-medium"
            >
              {{ sessionStatusStr(item.status) }}
            </v-avatar>

            <div class="title ml-2">
              <v-badge
                class="text-no-wrap mr-3"
                color="transparent"
              >
                <v-icon
                  slot="badge"
                  small
                >
                  <template v-if="item.application === Consts.APPLICATION.BEESECURE">
                    icon-bee48
                  </template>
                  <template v-else>
                    icon-kayak48
                  </template>
                </v-icon>
                <b>#{{ item.reference }}</b>
              </v-badge>
              <template v-if="item.organization">
                <b> /</b>
                <span
                  style="font-weight: 400 !important;"
                  class="ml-2"
                >{{ item.organization.name }}</span>
              </template>
            </div>
            <div
              layout="column"
              flex
              class="full-width"
            >
              <div
                layout="row"
                layout-align="center end"
              >
                <span flex />
                <v-btn
                  icon
                  class="mr-1"
                  @click="closeDates(item)"
                >
                  <v-icon
                    left
                    color="red"
                    right
                  >
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div
            layout="row"
            layout-align="start center"
          >
            <v-chip
              :color="sessionTypeColor(item.session_type)"
              class="lighten-3"
              small
            >
              {{ sessionTypeStr(item.session_type) }}
            </v-chip>

            <v-rating
              v-if="item.organization"
              background-color="#AAAAAA"
              dense
              small
              class="mr-1"
              readonly
              :value="parseInt(item.organization.mark)"
            />
            <v-rating
              v-else
              background-color="#AAAAAA"
              dense
              small
              class="mr-1"
              readonly
              :value="0"
            />
          </div>
        </v-card-title>
        <v-card-text
          v-if="datesEditMenus[item.id]"
          @keydown.enter="saveEvent(item, 'dates'); closeDates(item, true)"
        >
          <v-divider />
          <div class="subheading font-weight-regular mt-3">
            Change the session's dates:
          </div>

          <div
            v-for="(d, i) in item.dates"
            :key="item.id + '_d' + i"
            layout="row"
            layout-align="start center"
          >
            <v-menu
              ref="startDateMenu"
              v-model="d.date_start_visible"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              :return-value.sync="d.date_start"
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <v-icon slot="activator">
                event
              </v-icon>
              <v-date-picker
                v-model="d.date_start"
                first-day-of-week="1"
                @input="$refs.startDateMenu[i].save(d.date_start); d.date_start_text = americanizeDate(d.date_start)"
              />
            </v-menu>
            <v-text-field
              v-model="d.date_start_text"
              label="date"
              class="mr-4"
              @blur="d.date_start = parseDate(d.date_start_text)"
            />

            <v-menu
              ref="startClockMenu"
              v-model="d.clock_start_visible"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="d.clock_start"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              max-width="290px"
              min-width="290px"
            >
              <v-icon slot="activator">
                access_time
              </v-icon>
              <v-time-picker
                v-if="d.clock_start_visible"
                v-model="d.clock_start"
                format="24hr"
                @change="$refs.startClockMenu[i].save(d.clock_start)"
              />
            </v-menu>
            <v-text-field
              v-model="d.clock_start"
              label="from"
              class="mr-4"
            />
            <v-menu
              ref="endClockMenu"
              v-model="d.clock_end_visible"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="d.clock_end"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              max-width="290px"
              min-width="290px"
            >
              <v-icon slot="activator">
                access_time
              </v-icon>
              <v-time-picker
                v-if="d.clock_end_visible"
                v-model="d.clock_end"
                format="24hr"
                @change="$refs.endClockMenu[i].save(d.clock_end)"
              />
            </v-menu>
            <v-text-field
              v-model="d.clock_end"
              label="to"
            />
            <v-btn
              icon
              color="error"
              @click="removeDate(item, i)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <v-divider />
          <div class="subheader font-weight-regular mt-3">
            Session's dates:
          </div>
          <div>
            <div
              v-for="(d, i) in item.dates"
              :key="item.id + '_date' + i"
              class="d-flex"
            >
              <div class="d-flex justify-content-center">
                <v-icon>mdi-calendar</v-icon>
              </div>
              <div>
                <div>
                  <strong>Working hours : </strong>
                  {{ formatDateTimeHuman(d.start) }} <v-icon :size="18">
                    mdi-arrow-right
                  </v-icon> {{ formatDateTimeHuman(d.end) }}
                </div>
                <div>
                  <strong>Participants presence : </strong>
                  {{ formatDateTimeHuman(d.participants_start) }} <v-icon :size="18">
                    mdi-arrow-right
                  </v-icon> {{ formatDateTimeHuman(d.participants_end) }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          v-if="datesEditMenus[item.id]"
          layout-align="start center"
        >
          <v-btn
            flat
            @click="addDate(item)"
          >
            <v-icon
              color="primary"
              left
            >
              mdi-plus-box
            </v-icon> Add a new date
          </v-btn>
          <span flex />
          <v-btn
            flat
            @click="saveEvent(item, 'dates'); closeDates(item, true)"
          >
            <v-icon
              color="primary"
              left
            >
              mdi-floppy
            </v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <!-- Organization -->
    <v-menu
      v-model="organizationsMenus[item.id]"
      lazy
      offset-y
      offset-x
      :position-x="item.x"
      :position-y="item.y"
      absolute
      bottom
      right
      z-index="20"
    >
      <v-card>
        <v-card-title
          class="title"
          layout="row"
          layout-align="start center"
        >
          <v-rating
            v-if="item.organization"
            background-color="#AAAAAA"
            dense
            small
            class="mr-1"
            readonly
            :value="parseInt(item.organization.mark)"
          />
          <v-rating
            v-else
            background-color="#AAAAAA"
            dense
            small
            class="mr-1"
            readonly
            :value="0"
          />
          {{ item.organization ? item.organization.name : '' }}
          <div
            layout="column"
            flex
            class="full-width"
          >
            <div
              layout="row"
              layout-align="center end"
            >
              <span flex />
              <v-btn
                icon
                class="mr-1"
                @click="item.orga_popover_open = false"
              >
                <v-icon
                  left
                  color="red"
                  right
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-text v-if="item.organization">
          {{ item.organization.number }} {{ item.organization.street }}<br>
          {{ item.organization.zip_code }} {{ item.organization.city }}
          <div
            v-if="item.organization.phone"
            layout="row"
          >
            <v-icon small>
              mdi-phone
            </v-icon>&nbsp; {{ item.organization.phone }}
          </div>
          <div
            v-if="item.organization.email"
            layout="row"
          >
            <v-icon small>
              mdi-email
            </v-icon>&nbsp; {{ item.organization.email }}
          </div>
          <div
            v-if="item.organization.parking"
            layout="row"
          >
            <v-icon small>
              mdi-parking
            </v-icon>&nbsp; {{ item.organization.parking }}
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
    <!-- Class -->
    <v-menu
      v-model="classesMenus[item.id]"
      lazy
      :close-on-click="classesCloseMenus[item.id]"
      :close-on-content-click="false"
      offset-y
      offset-x
      :position-x="item.x"
      :position-y="item.y"
      absolute
      bottom
      right
      z-index="20"
    >
      <v-card
        style="width: 600px"
        @keydown.tab.stop="() => {}"
      >
        <v-card-title
          layout="column"
          layout-align="start start"
          class="pb-0"
        >
          <div
            layout="row"
            layout-align="start center"
            class="ml-1 mr-1"
            style="width: 100%"
          >
            <v-avatar
              :title="sessionStatusStrLong(item.status)"
              :color="sessionStatusColor(item.status)"
              :size="32"
              class="black--text font-weight-medium"
            >
              {{ sessionStatusStr(item.status) }}
            </v-avatar>
            <div class="title ml-2">
              <v-badge
                class="text-no-wrap mr-3"
                color="transparent"
              >
                <v-icon
                  slot="badge"
                  small
                >
                  <template v-if="item.application === Consts.APPLICATION.BEESECURE">
                    icon-bee48
                  </template>
                  <template v-else>
                    icon-kayak48
                  </template>
                </v-icon>
                <b>#{{ item.reference }}</b>
              </v-badge>
              <span v-if="item.organization">
                /
                <span
                  style="font-weight: 400 !important;"
                  class="ml-2"
                >{{ item.organization.name }}</span>
              </span>
            </div>
            <div
              layout="column"
              flex
              class="full-width"
            >
              <div
                layout="row"
                layout-align="center end"
              >
                <span flex />
                <v-btn
                  icon
                  class="mr-1"
                  @click="closeClass(item)"
                >
                  <v-icon
                    left
                    color="red"
                    right
                  >
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div
            layout="row"
            layout-align="start center"
          >
            <v-chip
              :color="sessionTypeColor(item.session_type)"
              class="lighten-3"
              small
            >
              {{ sessionTypeStr(item.session_type) }}
            </v-chip>
            <v-rating
              v-if="item.organization"
              background-color="#AAAAAA"
              dense
              small
              class="mr-1"
              readonly
              :value="parseInt(item.organization.mark)"
            />
            <v-rating
              v-else
              background-color="#AAAAAA"
              dense
              small
              class="mr-1"
              readonly
              :value="0"
            />
          </div>
        </v-card-title>
        <v-card-text
          v-if="classesEditMenus[item.id]"
          @keydown.enter="saveEvent(item, 'class'); closeClass(item, true)"
        >
          <v-divider />
          <div class="subheading font-weight-medium mt-3 mb-2">
            Change the session's class info:
          </div>
          <v-text-field
            ref="classfield"
            v-model="item.class_name"
            autofocus
            outline
            :label="(item.session_type == Consts.SCHOOL) ? 'Class name' : 'Extra'"
          />
          <v-text-field
            v-if="item.session_type == Consts.SCHOOL"
            v-model="item.room"
            outline
            label="Room"
          />
          <div v-if="item.session_type == Consts.SCHOOL">
            <v-text-field
              v-model="item.referent_fullname"
              outline
              label="Teacher fullname"
            />
            <v-text-field
              v-model="item.referent_phone"
              outline
              label="Teacher phone"
            />
          </div>
          <div v-else-if="item.session_type == Consts.TEACHERS">
            <v-text-field
              v-model="item.referent_fullname"
              outline
              label="Partner fullname"
            />
            <v-text-field
              v-model="item.referent_phone"
              outline
              label="Partner phone"
            />
          </div>
          <div v-else-if="item.session_type == Consts.YOUTH">
            <v-text-field
              v-model="item.referent_fullname"
              outline
              label="Educator fullname"
            />
            <v-text-field
              v-model="item.referent_phone"
              outline
              label="Educator phone"
            />
          </div>
          <v-select
            v-if="item.session_type != Consts.INTERNAL"
            slot="input"
            v-model="item.lang"
            v-focus
            outline
            label="Language"
            :items="langs"
            item-text="name"
            item-value="id"
            return-object
            @input="item.lang_id = item.lang.id"
          />
        </v-card-text>
        <v-card-text v-else>
          <v-divider />
          <div
            v-if="item.session_type == Consts.SCHOOL || item.session_type == Consts.TEACHERS || item.session_type == Consts.YOUTH"
            class="mt-3 mb-2"
          >
            <span class=" font-weight-medium">Session's teacher:</span>
            {{ item.referent_fullname }} {{ item.referent_phone }}
          </div>
          <div
            v-if="item.session_type == Consts.SCHOOL"
            class="mb-2"
          >
            <span class=" font-weight-medium mt-3 mb-2">Session's class / room:</span>
            {{ item.class_name ? item.class_name : "-" }} / {{ item.room ? item.room : "-" }}
          </div>
          <div
            v-else
            class="mb-2"
          >
            <span class=" font-weight-medium mt-3 mb-2">Extra:</span>
            {{ item.class_name ? item.class_name : "-" }}
          </div>
        </v-card-text>
        <v-card-actions
          v-if="classesEditMenus[item.id]"
          layout-align="start center"
        >
          <span flex />
          <v-btn
            flat
            @click="saveEvent(item, 'class'); closeClass(item, true)"
          >
            <v-icon
              color="primary"
              left
            >
              mdi-floppy
            </v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <!-- Target group -->
    <v-menu
      v-model="tgMenus[item.id]"
      lazy
      :close-on-click="tgCloseMenus[item.id]"
      :close-on-content-click="false"
      offset-y
      offset-x
      :position-x="item.x"
      :position-y="item.y"
      absolute
      bottom
      right
      z-index="20"
    >
      <v-card
        v-if="tgEditMenus[item.id]"
        style="width: 600px"
        @keydown.tab.stop="() => {}"
      >
        <v-card-title
          layout="column"
          layout-align="start start"
          class="pb-0"
        >
          <div
            layout="row"
            layout-align="start center"
            class="ml-1 mr-1"
            style="width: 100%"
          >
            <v-avatar
              :title="sessionStatusStrLong(item.status)"
              :color="sessionStatusColor(item.status)"
              :size="32"
              class="black--text font-weight-medium"
            >
              {{ sessionStatusStr(item.status) }}
            </v-avatar>

            <div class="title ml-2">
              <v-badge
                class="text-no-wrap mr-3"
                color="transparent"
              >
                <v-icon
                  slot="badge"
                  small
                >
                  <template v-if="item.application === Consts.APPLICATION.BEESECURE">
                    icon-bee48
                  </template>
                  <template v-else>
                    icon-kayak48
                  </template>
                </v-icon>
                <b>#{{ item.reference }}</b>
              </v-badge>
              <template v-if="item.organization">
                <b> /</b>
                <span
                  style="font-weight: 400 !important;"
                  class="ml-2"
                >{{ item.organization.name }}</span>
              </template>
            </div>
            <div
              layout="column"
              flex
              class="full-width"
            >
              <div
                layout="row"
                layout-align="center end"
              >
                <span flex />
                <v-btn
                  icon
                  class="mr-1"
                  @click="closeTg(item)"
                >
                  <v-icon color="red">
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div
            layout="row"
            layout-align="start center"
          >
            <v-chip
              :color="sessionTypeColor(item.session_type)"
              class="lighten-3"
              small
            >
              {{ sessionTypeStr(item.session_type) }}
            </v-chip>
            <v-rating
              v-if="item.organization"
              background-color="#AAAAAA"
              dense
              small
              class="mr-1"
              readonly
              :value="parseInt(item.organization.mark)"
            />
            <v-rating
              v-else
              background-color="#AAAAAA"
              dense
              small
              class="mr-1"
              readonly
              :value="0"
            />
          </div>
        </v-card-title>
        <v-card-text @keydown.enter="saveEvent(item, 'tg'); closeTg(item, true)">
          <v-divider />
          <div class="subheading font-weight-medium mt-3">
            Change the session's target group:
          </div>
          <v-select
            slot="input"
            v-model="item.target_group"
            v-focus
            outline
            label="Target group"
            :items="targetGroups"
            item-text="name"
            item-value="id"
            return-object
            @input="item.target_group_id = item.target_group.id"
          />
        </v-card-text>
        <v-card-actions
          v-if="tgEditMenus[item.id]"
          layout-align="start center"
        >
          <span flex />
          <v-btn
            flat
            @click="saveEvent(item, 'tg'); closeTg(item, true)"
          >
            <v-icon
              color="primary"
              left
            >
              mdi-floppy
            </v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <!-- Pupils -->
    <v-menu
      v-model="pupilsMenus[item.id]"
      lazy
      :close-on-click="pupilsCloseMenus[item.id]"
      :close-on-content-click="false"
      offset-y
      offset-x
      :position-x="item.x"
      :position-y="item.y"
      absolute
      bottom
      left
      z-index="20"
    >
      <v-card
        v-if="pupilsEditMenus[item.id]"
        style="width: 600px"
        @keydown.tab.stop="() => {}"
      >
        <v-card-title
          layout="column"
          layout-align="start start"
          class="pb-0"
        >
          <div
            layout="row"
            layout-align="start center"
            class="ml-1 mr-1"
            style="width: 100%"
          >
            <v-avatar
              :title="sessionStatusStrLong(item.status)"
              :color="sessionStatusColor(item.status)"
              :size="32"
              class="black--text font-weight-medium"
            >
              {{ sessionStatusStr(item.status) }}
            </v-avatar>
            <div class="title ml-2">
              <v-badge
                class="text-no-wrap mr-3"
                color="transparent"
              >
                <v-icon
                  slot="badge"
                  small
                >
                  <template v-if="item.application === Consts.APPLICATION.BEESECURE">
                    icon-bee48
                  </template>
                  <template v-else>
                    icon-kayak48
                  </template>
                </v-icon>
                <b>#{{ item.reference }}</b>
              </v-badge>
              <template v-if="item.organization">
                <b> /</b>
                <span
                  style="font-weight: 400 !important;"
                  class="ml-2"
                >{{ item.organization.name }}</span>
              </template>
            </div>
            <div
              layout="column"
              flex
              class="full-width"
            >
              <div
                layout="row"
                layout-align="center end"
              >
                <span flex />
                <v-btn
                  icon
                  class="mr-1"
                  @click="closePupils(item)"
                >
                  <v-icon color="red">
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div
            layout="row"
            layout-align="start center"
          >
            <v-chip
              :color="sessionTypeColor(item.session_type)"
              class="lighten-3"
              small
            >
              {{ sessionTypeStr(item.session_type) }}
            </v-chip>
            <v-rating
              v-if="item.organization"
              background-color="#AAAAAA"
              dense
              small
              class="mr-1"
              readonly
              :value="parseInt(item.organization.mark)"
            />
            <v-rating
              v-else
              background-color="#AAAAAA"
              dense
              small
              class="mr-1"
              readonly
              :value="0"
            />
          </div>
        </v-card-title>
        <v-card-text @keydown.enter="saveEvent(item, 'pupils'); closePupils(item, true)">
          <v-divider />
          <div class="subheading font-weight-medium mt-3 mb-2">
            Change the session's number of pupils / participants:
          </div>
          <v-text-field
            v-model="item.nb_participants"
            outline
            type="number"
            label="Number of participants"
            min="0"
          />
        </v-card-text>
        <v-card-actions
          v-if="pupilsEditMenus[item.id]"
          layout-align="start center"
        >
          <span flex />
          <v-btn
            flat
            @click="saveEvent(item, 'pupils'); closePupils(item, true)"
          >
            <v-icon
              color="primary"
              left
            >
              mdi-floppy
            </v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <!-- Contacts -->
    <v-menu
      v-model="contactsMenus[item.id]"
      lazy
      :close-on-click="contactsCloseMenus[item.id]"
      :close-on-content-click="false"
      offset-y
      offset-x
      :position-x="item.x"
      :position-y="item.y"
      absolute
      bottom
      left
      z-index="20"
    >
      <v-card
        style="width: 600px"
        @keydown.tab.stop="() => {}"
      >
        <v-card-title
          layout="column"
          layout-align="start start"
          class="pb-0"
        >
          <div
            layout="row"
            layout-align="start center"
            class="ml-1 mr-1"
            style="width: 100%"
          >
            <v-avatar
              :title="sessionStatusStrLong(item.status)"
              :color="sessionStatusColor(item.status)"
              :size="32"
              class="black--text font-weight-medium"
            >
              {{ sessionStatusStr(item.status) }}
            </v-avatar>
            <div class="title ml-2">
              <v-badge
                class="text-no-wrap mr-3"
                color="transparent"
              >
                <v-icon
                  slot="badge"
                  small
                >
                  <template v-if="item.application === Consts.APPLICATION.BEESECURE">
                    icon-bee48
                  </template>
                  <template v-else>
                    icon-kayak48
                  </template>
                </v-icon>
                <b>#{{ item.reference }}</b>
              </v-badge>
              <template v-if="item.organization">
                <b> /</b>
                <span
                  style="font-weight: 400 !important;"
                  class="ml-2"
                >{{ item.organization.name }}</span>
              </template>
            </div>
            <div
              layout="column"
              flex
              class="full-width"
            >
              <div
                layout="row"
                layout-align="center end"
              >
                <span flex />
                <v-btn
                  icon
                  class="mr-1"
                  @click="closeContacts(item)"
                >
                  <v-icon color="red">
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div
            layout="row"
            layout-align="start center"
          >
            <v-chip
              :color="sessionTypeColor(item.session_type)"
              class="lighten-3"
              small
            >
              {{ sessionTypeStr(item.session_type) }}
            </v-chip>
            <v-rating
              v-if="item.organization"
              background-color="#AAAAAA"
              dense
              small
              class="mr-1"
              readonly
              :value="parseInt(item.organization.mark)"
            />
            <v-rating
              v-else
              background-color="#AAAAAA"
              dense
              small
              class="mr-1"
              readonly
              :value="0"
            />
          </div>
        </v-card-title>
        <v-card-text
          v-if="contactsEditMenus[item.id]"
          @keydown.enter="saveEvent(item, 'contacts'); closeContacts(item, true)"
        >
          <v-divider />
          <div class="subheading font-weight-medium mt-3">
            Change the session's contacts:
          </div>
          <v-select
            v-if="item.contacts"
            v-model="item.contact"
            v-focus
            :disabled="!item.contacts.length"
            outline
            label="Contact *"
            :loading="loading"
            :rules="[ v => !!v || 'This field is required' ]"
            :item-text="fullname"
            item-value="id"
            :items="item.contacts"
            return-object
            @input="item.contact_id = item.contact.id"
          />
          <v-select
            v-if="item.contacts"
            v-model="item.contact2"
            v-focus
            :disabled="!item.contacts.length"
            outline
            label="Contact 2"
            :loading="loading"
            :item-text="fullname"
            item-value="id"
            :items="item.contacts"
            return-object
            @input="item.contact2_id = item.contact2.id"
          />
        </v-card-text>
        <v-card-text v-else>
          <v-divider />
          <div class="subheading font-weight-medium mt-3 mb-2">
            Session's contacts:
          </div>
          <div
            v-if="item.contact"
            layout="row"
            class="mr-3"
          >
            <div
              layout="column"
              flex="30"
            >
              Main contact:
            </div>
            <div
              layout="column"
              flex="30"
            >
              {{ item.contact.firstname }} {{ item.contact.lastname }}
            </div>
            <div
              layout="column"
              flex="20"
            >
              <div layout="row">
                <v-icon
                  small
                  class="mr-1"
                >
                  mdi-phone
                </v-icon> {{ item.contact.phone ? item.contact.phone : "/" }}
              </div>
            </div>
            <div
              layout="column"
              flex="20"
            >
              <div
                layout="row"
                class="dont-break-out"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  mdi-email
                </v-icon> {{ item.contact.email ? item.contact.email : "/" }}
              </div>
            </div>
          </div>
          <div
            v-if="item.contact2"
            layout="row"
            class="mr-3"
          >
            <div
              layout="column"
              flex="30"
            >
              Secondary contact:
            </div>
            <div
              layout="column"
              flex="30"
            >
              {{ item.contact2.firstname }} {{ item.contact2.lastname }}
            </div>
            <div
              layout="column"
              flex="20"
            >
              <div layout="row">
                <v-icon
                  small
                  class="mr-1"
                >
                  mdi-phone
                </v-icon> {{ item.contact2.phone ? item.contact2.phone : "/" }}
              </div>
            </div>
            <div
              layout="column"
              flex="20"
            >
              <div
                layout="row"
                class="dont-break-out"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  mdi-email
                </v-icon> {{ item.contact2.email ? item.contact2.email : "/" }}
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          v-if="contactsEditMenus[item.id]"
          layout-align="start center"
        >
          <span flex />
          <v-btn
            flat
            @click="saveEvent(item, 'contacts'); closeContacts(item, true)"
          >
            <v-icon
              color="primary"
              left
            >
              mdi-floppy
            </v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <!-- Upgrade -->
    <v-menu
      v-model="upgradeModal[item.id]"
      :close-on-content-click="false"
      :disabled="!isUpgradable(item.status, item)"
      lazy
      offset-y
      offset-x
      :position-x="item.x"
      :position-y="item.y"
      absolute
      bottom
      left
      z-index="20"
    >
      <v-card style="max-width: 450px">
        <v-card-title class="title">
          Upgrade session
        </v-card-title>
        <v-card-text>
          {{ textUpgrade(item) }}
        </v-card-text>
        <v-card-actions
          layout="row"
          layout-align="end center"
        >
          <v-btn
            depressed
            color="error"
            @click="upgradeModal[item.id] = false"
          >
            No
          </v-btn>
          <v-btn
            depressed
            color="success"
            @click="validate(item.id, false)"
          >
            Yes without notifying
          </v-btn>
          <v-btn
            depressed
            color="primary"
            @click="validate(item.id, true)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <div
      layout="row"
      layout-align="start center"
      class="mt-3 index-footer"
      style="color: #bfbfbf;"
    >
      <v-icon color="#d9d9d9">
        mdi-eye
      </v-icon>&nbsp;
      one click to show details of the cell
      <span v-if="isCoord || isAdmin">
        <v-icon
          class="ml-4"
          color="#d9d9d9"
        >mdi-pencil</v-icon>&nbsp;
        double-click to edit details of the cell
      </span>
      <span flex />
      <v-pagination
        v-model="pagination.page"
        class="ml-2"
        :length="ceil(totalSessions / pagination.rowsPerPage)"
        :total-visible="10"
        @input="fetch(true)"
      />
      <span class="mx-2 black--text">Rows per page:</span>
      <v-select
        v-model="pagination.rowsPerPage"
        :items="rows"
        hide-details
        single-line
        class=" mt-0 pt-0"
        item-value="value"
        item-text="text"
        style="max-width: min-content"
        @change="fetch(true, true)"
      />
    </div>

    <join-dialog
      :visible.sync="joinDialog"
      :session-id="editingSession.id"
      :value="{}"
      @updated="fetch"
    />
    <trainer-eval-dialog
      :visible.sync="trainerEvalDialog"
      :session="editingSession"
      :value="editingTrainerEval"
      @created="fetch"
      @updated="fetch"
    />
    <block-dialog
      :visible.sync="blockDialog"
      :sess="editingSession"
      @created="fetch"
      @updated="fetch"
    />
    <to-eval-dialog
      :visible.sync="toEvalDialog"
      :session="editingSession"
      @created="fetch"
      @updated="fetch"
    />
  </div>
</template>

<script>
import moment from "moment";
import Sessions from "@/api/sessions";
import Organizations from "@/api/organizations";
import Isolangs from "@/api/isolangs";
import Params from "@/api/params";
import DateMixin from "@/mixins/date";
import SessionMixin from "@/mixins/sessions";
import GlobalMixin from "@/mixins/global";
import Consts from "@/consts";
import Auth from "@/plugins/auth";
import JoinDialog from "@/components/shared/JoinSessionDialog.vue";
import TrainerEvalDialog from "@/components/shared/TrainerEvalDialog.vue";
import BlockDialog from "@/components/shared/BlockDialog.vue";
import ToEvalDialog from "@/components/shared/ToEvalDialog.vue";
import Vue from "vue";
import axios from "axios";
import CommentIcon from "@/components/shared/CommentIcon";
import SpecialTrainingIcon from "@/components/shared/SpecialTrainingIcon";

export default {
  components: {
    JoinDialog,
    TrainerEvalDialog,
    BlockDialog,
    ToEvalDialog,
    CommentIcon,
    SpecialTrainingIcon
  },
  mixins: [DateMixin, SessionMixin, GlobalMixin],
  data() {
    return {
      filters: [
        { value: 1, text: "To-Dos" },
        { value: 2, text: "To-Dos School training" },
        { value: 3, text: "Historic" }
      ],
      sessions: [],
      upgradeModal: {},
      downgradeModal: {},
      totalSessions: 0,
      loading: true,
      pagination: {
        sortBy: "updated_at",
        descending: true,
        page: 1,
        rowsPerPage: 10
      },
      isTrainer: false,
      isCoord: false,
      isAdmin: false,
      joinDialog: false,
      blockDialog: false,
      toEvalDialog: false,
      editingSession: {},
      advancedSearch: false,
      iconSize: 21,
      params: {
        start: null,
        end: null,
        start_text: null,
        end_text: null,
        last_text: null,
        last: null,
        school: null,
        contact: null,
        address: null,
        event: null,
        comments: null,
        ticket_number: null,
        trainers: [],
        visitors: [],
        langs: [],
        status: [],
        session_type: [],
        target_group_id: [],
        team_id: [],
        topic_id: [],
        school_type_id: [],
        application: '',
        not_fully_eval: null,
        is_online: null,
        is_inclusive: null,
        reference: null,
        onlyMySessions: false
      },
      targetGroups: [],
      packages: [],
      teams: [],
      topics: [],
      langs: [],
      groups: [],
      schoolTypes: [],
      keywords: null,
      mode: 1,
      editingTrainerEval: {},
      trainerEvalDialog: false,
      init: true,
      storeKey: "beesecureParams",
      storeKey2: "beesecurePage",
      storeKey3: "beesecureMode",
      firstLoaded: false,
      source: null,
      trainers: [],
      visitors: [],
      searchLast: null,
      Consts,
      rows: [
        {
          text: "10",
          value: 10
        },
        {
          text: "20",
          value: 20
        },
        {
          text: "50",
          value: 50
        },
        {
          text: "100",
          value: 100
        }
      ],
      item: {
        x: 0,
        y: 0
      },
      datesMenus: {},
      datesEditMenus: {},
      datesCloseMenus: {},
      organizationsMenus: {},
      classesMenus: {},
      classesEditMenus: {},
      classesCloseMenus: {},
      tgMenus: {},
      tgEditMenus: {},
      tgCloseMenus: {},
      pupilsMenus: {},
      pupilsEditMenus: {},
      pupilsCloseMenus: {},
      contactsMenus: {},
      contactsEditMenus: {},
      contactsCloseMenus: {},
      delay: 700,
      timer: null,
      datesClicks: 0,
      classClicks: 0,
      loadingPresences: false,
      sessionForm: false,
      newSession: {
        application: this.getDefaultApp(),
        session_type: null,
        is_package: 0,
        package_id: null,
        start_text: '',
        start_date: null
      }
    };
  },
  computed: {
    headers () {
      if(this.isAdmin || this.isCoord){
        return [
          { text: "#", value: "reference", sortable: false, class: "id-col" },
          { text: "Type", value: "session_type", noPrintable: true },
          {
            text: "Date(s)",
            value: "sd.start",
            icons: ["pencil"],
            class: this.isTrainer ? 'dates-col-trainer' : 'dates-col'
          },
          {
            text: "Organization / Team",
            value: "org.name",
            icons: ["eye"],
            class: "orga-col"
          },
          {
            text: "Class / Room",
            value: "room",
            icons: ["eye", "pencil"],
            class: "class-col"
          },
          { text: "Concept", value: "topic.name", class: "ref-col" },
          {
            text: "Target group",
            value: "tg.name",
            icons: ["pencil"],
            noPrintable: true
          },
          {
            text: "Pupils",
            value: "nb_participants",
            icons: ["pencil"],
            class: "avatar-col"
          },
          {
            text: "Contact(s)",
            value: "c.firstname",
            icons: ["eye", "pencil"],
            class: "contacts-col"
          },
          {
            text: "Trainer(s) / Visitor(s)",
            value: "trainer.lastname",
            noPrintable: true
          },
          { text: "Last update", value: "updated_at", noPrintable: true },
          { text: "Status", value: "status", class: "avatar-col" },
          { text: "Actions", sortable: false, noPrintable: true }
        ]
      }
      return [
        { text: "#", value: "reference", sortable: false, class: "id-col" },
        { text: "Type", value: "session_type", noPrintable: true },
        { text: "Date(s)", value: "sd.start", icons: [], class: this.isTrainer ? 'dates-col-trainer' : 'dates-col' },
        {
          text: "Organization / Team",
          value: "org.name",
          icons: ["eye"],
          class: "orga-col"
        },
        {
          text: "Class / Room",
          value: "room",
          icons: ["eye"],
          class: "class-col"
        },
        { text: "Concept", value: "topic.name", class: "ref-col" },
        {
          text: "Target group",
          value: "tg.name",
          icons: [],
          noPrintable: true
        },
        {
          text: "Pupils",
          value: "nb_participants",
          width: 70,
          icons: [],
          class: "avatar-col"
        },
        {
          text: "Contact(s)",
          value: "c.firstname",
          icons: ["eye"],
          class: "contacts-col"
        },
        { text: "Trainer(s)", value: "trainer.lastname", noPrintable: true },
        {
          text: "Last update",
          value: "updated_at",
          width: 100,
          noPrintable: true
        },
        { text: "Status", value: "status", class: "avatar-col" },
        { text: "Actions", sortable: false, width: 160, noPrintable: true }
      ]
    },
    sessionTypes() {
      const bs = [
        { value: Consts.EVENTS, text: this.sessionTypeStr(Consts.EVENTS) },
        { value: Consts.ADULTS, text: this.sessionTypeStr(Consts.ADULTS) },
        { value: Consts.SCHOOL, text: this.sessionTypeStr(Consts.SCHOOL) },
        { value: Consts.TEACHERS, text: this.sessionTypeStr(Consts.TEACHERS) },
        { value: Consts.YOUTH, text: this.sessionTypeStr(Consts.YOUTH) },
        { value: Consts.INTERNAL, text: this.sessionTypeStr(Consts.INTERNAL) },
        { value: Consts.OTHER, text: this.sessionTypeStr(Consts.OTHER) }
      ];

      const fl = [
        {
          value: Consts.FREELANCES_TRAINING,
          text: this.sessionTypeStr(Consts.FREELANCES_TRAINING)
        },
        { value: Consts.ACTIVITY, text: this.sessionTypeStr(Consts.ACTIVITY) }
      ];

      switch (this.params.application) {
        case Consts.APPLICATION.BEESECURE:
          return bs;
        case Consts.APPLICATION.FREELANCES:
          return fl;
        case Consts.APPLICATION.ALL:
        default:
          return bs.concat(fl);
      }
    },
    sessionTypesLong() {
      if (
        this.getDefaultApp() === Consts.APPLICATION.ALL &&
        !this.newSession.application
      ) {
        return [];
      }
      if (
        this.getDefaultApp() === Consts.APPLICATION.FREELANCES ||
        this.newSession.application === Consts.APPLICATION.FREELANCES
      ) {
        return [
          {
            value: Consts.FREELANCES_TRAINING,
            text: this.sessionTypeStrLong(Consts.FREELANCES_TRAINING)
          },
          {
            value: Consts.ACTIVITY,
            text: this.sessionTypeStrLong(Consts.ACTIVITY)
          }
        ];
      }
      if (
        this.getDefaultApp() === Consts.APPLICATION.BEESECURE ||
        this.newSession.application === Consts.APPLICATION.BEESECURE
      ) {
        return [
          {
            value: Consts.EVENTS,
            text: this.sessionTypeStrLong(Consts.EVENTS)
          },
          {
            value: Consts.ADULTS,
            text: this.sessionTypeStrLong(Consts.ADULTS)
          },
          {
            value: Consts.SCHOOL,
            text: this.sessionTypeStrLong(Consts.SCHOOL)
          },
          {
            value: Consts.TEACHERS,
            text: this.sessionTypeStrLong(Consts.TEACHERS)
          },
          { value: Consts.YOUTH, text: this.sessionTypeStrLong(Consts.YOUTH) },
          {
            value: Consts.INTERNAL,
            text: this.sessionTypeStrLong(Consts.INTERNAL)
          },
          { value: Consts.OTHER, text: this.sessionTypeStrLong(Consts.OTHER) }
        ];
      }

      return []
    },
    status() {
      let out = [
        { value: Consts.CANCELLED, text: "Cancelled" },
        { value: Consts.RELEASE, text: "Release" },
        { value: Consts.TOVALIDATE, text: "To validate" },
        { value: Consts.BLOCKED, text: "Blocked" },
        { value: Consts.TOEVALUATE, text: "To evaluate" },
        { value: Consts.CLOSED, text: "Closed" }
      ];
      if (this.isCoord || this.isAdmin) {
        out = [
          ...[
            { value: Consts.ONHOLD, text: "On hold" },
            { value: Consts.DRAFT, text: "Draft" },
            { value: Consts.CONFIRMED, text: "Confirmed" }
          ],
          ...out
        ];
      }
      return out;
    },
    filtersArray() {
      let params = Object.assign(
        {},
        this.pagination,
        this.params,
        { keywords: this.keywords },
        { mode: this.mode }
      );
      return params;
    }
  },
  watch: {
    joinDialog(val) {
      if (!val) {
        this.editingSession = {};
      }
    },
    keywords(val) {
      if (val && this.isInt(val)) {
        this.params.reference = val;
        this.fetch();
      } else if (val && val.length > 2) {
        this.params.reference = null;
        this.fetch();
      } else if (val == "") {
        this.params.reference = null;
        this.fetch();
      }
    },
    trainerEvalDialog(val) {
      if (!val) {
        this.editingTrainerEval = {};
        this.editingSession = {};
      }
    }
  },
  beforeMount() {
    this.advancedSearch = !(localStorage.advancedSearchSessions === "false");
  },
  async mounted() {
    this.params.application = this.getStoredApp()
    this.fetch(true);
    this.fetchParams();
    await Auth.refresh();
    this.loadUser();
  },
  methods: {
    onKeywordsClear(){
      this.params.reference = null
      this.fetch(false, true)
    },
    createPackage(){
      Sessions.packageable(this.newSession.package_id, this.newSession).then(() => {
        this.$router.push({name: 'sessions-edit', params: {id: 'new'}, query:{type: this.newSession.session_type, application: this.newSession.application, package_id: this.newSession.package_id, start_date: this.newSession.start_date}})
      })
    },
    onSelectApp() {
      this.params.topic_id = [];
      this.params.session_type = [];
      this.fetch(false, true);
      this.fetchTopics();
    },
    isAlreadyTrainer(item) {
      for (let i in item.presences) {
        if (item.presences[i].is) {
          return true;
        }
      }

      return false;
    },
    updateEnd() {
      this.$refs.endPicker.monthClick(this.params.start);
    },
    ceil(x) {
      return Math.ceil(x);
    },
    americanizeDate(date) {
      if (!date) return null;

      if (date.indexOf(".") >= 0) {
        return date;
      }

      return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
    },
    parseDate(date) {
      if (!date) return null;

      if (date.indexOf("-") >= 0) {
        return date;
      }

      return moment(date, "DD.MM.YYYY").format("YYYY-MM-DD");
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }

      this.fetch();
    },
    toggleAdvancedSearch() {
      this.advancedSearch = !this.advancedSearch;
      localStorage.advancedSearchSessions = this.advancedSearch;
    },
    isInt(value) {
      var x;
      if (isNaN(value)) {
        return false;
      }
      x = parseFloat(value);
      return (x | 0) === x;
    },
    duplicate(id) {
      Sessions.duplicate(id).then(res => {
        this.$snotify.success("The session has been duplicated");
        this.$router.push({
          name: "sessions-show-info",
          params: { id: res.data.id }
        });
      });
    },
    evaluate(s) {
      if (this.canBeTeacherEval(s)) {
        this.$router.push({
          name: "sessions-show-teacher-evals",
          params: { id: s.id }
        });
      } else if (this.canBeTrainerEval(s)) {
        this.trainerEvalOpen(s);
      }
    },
    fetch(fromPaginate = false, initPage = false) {
      if (initPage) {
        this.pagination.page = 1;
      }

      if (this.init) {
        this.init = false;
        let json = JSON.parse(localStorage.getItem(this.storeKey3));
        if (json) {
          this.mode = json;
        }
        let json2 = JSON.parse(localStorage.getItem(this.storeKey));
        if (json2) {
          this.params = json2;
          this.params.application = this.getStoredApp();
          this.fetchTopics();
        }

        this.onSelectMode(false, json && json2 ? true : false);
        return;
      }

      this.loading = true;

      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        if (this.source) {
          this.source.cancel();
        }

        let CancelToken = axios.CancelToken;
        this.source = CancelToken.source();

        axios
          .get("/sessions", {
            cancelToken: this.source.token,
            params: this.filtersArray
          })
          .then(res => {
            this.sessions = Object.assign([], res.data.items);
            this.totalSessions = res.data.total;
            this.loading = false;

            for (let i = 0; i < this.sessions.length; ++i) {
              let s = this.sessions[i];

              // Parse dates into split date/time
              for (let j = 0; j < s.dates.length; j++) {
                let d = s.dates[j];

                d.date_start = moment(d.start).format("YYYY-MM-DD");
                d.date_start_text = this.americanizeDate(d.date_start);
                d.clock_start = moment(d.start).format("HH:mm:ss");
                d.clock_end = moment(d.end).format("HH:mm:ss");

                s.dates[j] = d;
              }

              if (
                this.pagination.sortBy == "sd.start" &&
                this.pagination.descending == true
              ) {
                s.dates.sort(
                  (a, b) => (a.start < b.start ? 1 : b.start < a.start ? -1 : 0)
                );
              }

              s.contacts = [];
              s.presences = {};

              if (s.dates) {
                for (let k = 0; k < s.dates.length; k++) {
                  s.presences[s.dates[k].id] = {
                    start: s.dates[k].start,
                    end: s.dates[k].end,
                    is: false,
                    is_blocked: false
                  };
                }
              }

              if (s.trainers_presences && s.trainers_presences.length) {
                let is = s.trainers_presences
                  .map(function(e) {
                    return e.user_id;
                  })
                  .indexOf(Auth.user.id);

                if (
                  is > -1 &&
                  s.trainers_presences[is].presences &&
                  s.trainers_presences[is].presences.length
                ) {
                  for (
                    let l = 0;
                    l < s.trainers_presences[is].presences.length;
                    l++
                  ) {
                    // La date pourrait avoir été supprimée
                    if (
                      s.presences[
                        s.trainers_presences[is].presences[l].session_date_id
                      ]
                    ) {
                      s.presences[
                        s.trainers_presences[is].presences[l].session_date_id
                      ].is = true;
                      s.presences[
                        s.trainers_presences[is].presences[l].session_date_id
                      ].is_blocked = s.trainers_presences[is].presences[l]
                        .is_blocked
                        ? true
                        : false;
                    }
                  }
                }
              }

              this.sessions[i] = s;
            }

            // this.loading = false
            localStorage.setItem(
              this.storeKey2,
              JSON.stringify(this.pagination)
            );
            localStorage.setItem(this.storeKey3, JSON.stringify(this.mode));
            localStorage.setItem(this.storeKey, JSON.stringify(this.params));
            this.setStoredApp(this.params.application);

            this.firstLoaded = true;
          })
          .catch(() => {
            this.loading = false;
            this.firstLoaded = true;
          });
      }, fromPaginate ? 0 : 300);
    },
    fetchTopics() {
      Params.getList("topics", {
        is_enabled: 1,
        application: this.params.application
      }).then(res => {
        this.topics = res.data;
      });
    },
    fetchParams() {
      Params.getList("packages", { is_enabled: 1 }).then(res => {
        this.packages = res.data;
      });
      Params.getList("targets-groups", { is_enabled: 1 }).then(res => {
        this.targetGroups = res.data;
      });
      Params.getList("teams", { is_enabled: 1 }).then(res => {
        this.teams = res.data;
        this.teams.unshift({ id: null, name: "None" });
      });
      Params.getList("groups", { role: Consts.TRAINER }).then(res => {
        this.groups = res.data;

        let groups = res.data;
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].users && groups[i].users.length > 0) {
            for (let j = 0; j < groups[i].users.length; j++) {
              let is = this.trainers
                .map(function(e) {
                  return e.user_id;
                })
                .indexOf(groups[i].users[j].id);

              if (is == -1 && groups[i].users[j].is_enabled) {
                groups[i].users[j]["fullname"] =
                  groups[i].users[j]["firstname"] +
                  " " +
                  groups[i].users[j]["lastname"];
                this.trainers.push(groups[i].users[j]);
              }
            }
          }
        }
        this.trainers = this.trainers.sort(
          (a, b) =>
            a.firstname > b.firstname ? 1 : b.firstname > a.firstname ? -1 : 0
        );

        //Supprime les doublons dans plusieurs groupes
        var uniq = new Set(this.trainers.map(e => JSON.stringify(e)));
        var result = Array.from(uniq).map(e => JSON.parse(e));
        this.trainers = result;
      });
      Params.getList("groups", {
        role: [Consts.TRAINER, Consts.COORDINATION]
      }).then(res => {
        let groups = res.data;
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].users && groups[i].users.length > 0) {
            for (let j = 0; j < groups[i].users.length; j++) {
              let is = this.visitors
                .map(function(e) {
                  return e.user_id;
                })
                .indexOf(groups[i].users[j].id);

              if (is == -1 && groups[i].users[j].is_enabled) {
                groups[i].users[j]["fullname"] =
                  groups[i].users[j]["firstname"] +
                  " " +
                  groups[i].users[j]["lastname"];
                this.visitors.push(groups[i].users[j]);
              }
            }
          }
        }
        this.visitors = this.visitors.sort(
          (a, b) =>
            a.firstname > b.firstname ? 1 : b.firstname > a.firstname ? -1 : 0
        );

        //Supprime les doublons dans plusieurs groupes
        var uniq = new Set(this.visitors.map(e => JSON.stringify(e)));
        var result = Array.from(uniq).map(e => JSON.parse(e));
        this.visitors = result;
      });
      Params.getList("schools-types", {
        is_enabled: 1,
        sortBy: "position"
      }).then(res => {
        this.schoolTypes = res.data;
        this.schoolTypes.push({
          id: null,
          name: "Undefined"
        });
      });
      Isolangs.getSpoken().then(res => {
        this.langs = res.data;
      });
    },
    getSessionDate(s) {
      let out = "";

      if (s.length === 0) {
        out += "-";
      } else if (s.length >= 1) {
        const d = s[0];
        out +=
          this.formatDayHuman(d.start) +
          " [" +
          this.formatTime(d.start) +
          " - " +
          this.formatTime(d.end) +
          "]";
      }

      if (s.length > 1) {
        out += " (+" + (s.length - 1) + " other)";
      }

      return out;
    },
    setPresence(sess) {
      this.loadingPresences = true;
      let pres = {};

      for (let i in sess.presences) {
        if (sess.presences[i].is == true) {
          pres[i] = true;
        }
      }

      Sessions.updatePresences(sess.id, { presences: pres }).then(
        res => {
          sess.trainers = res.data.trainers;
          sess.nb_trainers = res.data.nb_trainers;
          this.loadingPresences = false;
          this.$snotify.success("The presences have been updated");
        },
        () => {
          this.loadingPresences = false;
          this.fetch();
        }
      );
    },
    openUpgrade(ev, s) {
      s.x = ev.clientX;
      s.y = ev.clientY;
      this.item = s;
      Vue.set(this.upgradeModal, s.id, true);
    },
    openOrganization(ev, s) {
      s.x = ev.clientX;
      s.y = ev.clientY;
      this.item = s;
      Vue.set(this.organizationsMenus, s.id, true);
    },
    onDateDblClick(ev, item) {
      item.x = ev.clientX;
      item.y = ev.clientY;
      this.item = item;
      if (this.isAdmin || this.isAppCoord(item)) {
        this.setEditingSession(item);
        item.dates.sort(
          (a, b) => (a.start > b.start ? 1 : b.start > a.start ? -1 : 0)
        );
        setTimeout(() => {
          Vue.set(this.datesEditMenus, item.id, true);
          Vue.set(this.datesMenus, item.id, true);
        }, 200);
      } else {
        Vue.set(this.datesMenus, item.id, true);
      }
    },
    openDates(ev, s) {
      this.datesClicks++;
      if (this.datesClicks === 1) {
        this.timer = setTimeout(() => {
          s.x = ev.clientX;
          s.y = ev.clientY;
          this.item = s;
          if (!this.datesMenus[s.id]) {
            Vue.set(this.datesEditMenus, s.id, false);
            Vue.set(this.datesMenus, s.id, true);
            s.dates.sort(
              (a, b) => (a.start > b.start ? 1 : b.start > a.start ? -1 : 0)
            );
          }
          Vue.set(this.datesCloseMenus, s.id, false);
          setTimeout(() => {
            this.datesCloseMenus[s.id] = true;
          }, 1000);
          this.datesClicks = 0;
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        this.onDateDblClick(ev, s);
        this.datesClicks = 0;
      }
    },
    closeDates(item, saved = false) {
      this.datesMenus[item.id] = false;
      if (this.datesEditMenus[item.id]) {
        const clone = JSON.parse(this.editingSession);
        this.datesEditMenus[item.id] = false;
        if (!saved) {
          item.dates = clone.dates;
        }
      }
    },
    setEditingSession(sess) {
      this.editingSession = this.jsonCopy(sess);
    },
    jsonCopy(src) {
      return JSON.stringify(src);
    },
    onContactDblClick(ev, item) {
      item.x = ev.clientX;
      item.y = ev.clientY;
      this.item = item;

      if (this.isAdmin || this.isAppCoord(item)) {
        this.setEditingSession(item);
        Organizations.getContactList(item.organization_id, {
          is_enabled: 1
        }).then(res => {
          item.contacts = res.data;
          Vue.set(this.contactsEditMenus, item.id, true);
          Vue.set(this.contactsMenus, item.id, true);
        });
      } else {
        Vue.set(this.contactsMenus, item.id, true);
      }
    },
    openContact(ev, s) {
      s.x = ev.clientX;
      s.y = ev.clientY;
      this.item = s;

      if (!this.contactsMenus[s.id]) {
        Vue.set(this.contactsEditMenus, s.id, false);
        Vue.set(this.contactsMenus, s.id, true);
      }

      Vue.set(this.contactsCloseMenus, s.id, false);
      setTimeout(() => {
        this.contactsCloseMenus[s.id] = true;
      }, 1000);
    },
    closeContacts(item, saved = false) {
      this.contactsMenus[item.id] = false;
      if (this.contactsEditMenus[item.id]) {
        this.contactsEditMenus[item.id] = false;
        const clone = JSON.parse(this.editingSession);
        if (!saved) {
          item.contact_id = clone.contact_id;
          item.contact2_id = clone.contact2_id;
          item.contact = clone.contact;
          item.contact2 = clone.contact2;
        }
      }
    },
    onClassDblClick(ev, item) {
      if(this.isFreelancesObj(item)){
        return
      }

      item.x = ev.clientX;
      item.y = ev.clientY;
      this.item = item;
      if (this.isAdmin || this.isAppCoord(item)) {
        this.setEditingSession(item);
        Vue.set(this.classesEditMenus, item.id, true);
        Vue.set(this.classesMenus, item.id, true);
      } else {
        Vue.set(this.classesMenus, item.id, true);
      }
    },
    openClass(ev, s) {
      if(this.isFreelancesObj(s)){
        return
      }

      this.classClicks++;
      if (this.classClicks === 1) {
        this.timer = setTimeout(() => {
          s.x = ev.clientX;
          s.y = ev.clientY;
          this.item = s;
          if (!this.classesMenus[s.id]) {
            Vue.set(this.classesEditMenus, s.id, false);
            Vue.set(this.classesMenus, s.id, true);
          }
          Vue.set(this.classesCloseMenus, s.id, false);
          setTimeout(() => {
            this.classesCloseMenus[s.id] = true;
          }, 1000);
          this.classClicks = 0;
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        this.onClassDblClick(ev, s);
        this.classClicks = 0;
      }
    },
    closeClass(item, saved = false) {
      this.classesMenus[item.id] = false;
      if (this.classesEditMenus[item.id]) {
        const clone = JSON.parse(this.editingSession);
        this.classesEditMenus[item.id] = false;

        if (!saved) {
          item.room = clone.room;
          item.class_name = clone.class_name;
          item.referent_fullname = clone.referent_fullname;
          item.referent_phone = clone.referent_phone;
          item.lang_id = clone.lang_id;
          item.lang = clone.lang;
        }
      }
    },
    onTgDblClick(ev, item) {
      /*if (item.session_type == Consts.INTERNAL) {
        return
      }*/

      item.x = ev.clientX;
      item.y = ev.clientY;
      this.item = item;
      if (this.isAdmin || this.isAppCoord(item)) {
        this.setEditingSession(item);
        Vue.set(this.tgEditMenus, item.id, true);
        Vue.set(this.tgMenus, item.id, true);
      }
    },
    openTG(ev, s) {
      /*if (s.session_type == Consts.INTERNAL) {
        return
      }*/
      s.x = ev.clientX;
      s.y = ev.clientY;
      this.item = s;

      if (!this.tgMenus[s.id]) {
        Vue.set(this.tgEditMenus, s.id, false);
        Vue.set(this.tgMenus, s.id, true);
      }
      Vue.set(this.tgCloseMenus, s.id, false);
      setTimeout(() => {
        this.tgCloseMenus[s.id] = true;
      }, 1000);
    },
    closeTg(item, saved = false) {
      this.tgMenus[item.id] = false;
      if (this.tgEditMenus[item.id]) {
        const clone = JSON.parse(this.editingSession);
        this.tgEditMenus[item.id] = false;
        if (!saved) {
          item.target_group_id = clone.target_group_id;
          item.target_group = clone.target_group;
        }
      }
    },
    onPupilsDblClick(ev, item) {
      if (item.session_type == Consts.INTERNAL) {
        return;
      }

      item.x = ev.clientX - 200;
      item.y = ev.clientY;
      this.item = item;

      if (this.isAdmin || this.isAppCoord(item)) {
        this.setEditingSession(item);
        Vue.set(this.pupilsEditMenus, item.id, true);
        Vue.set(this.pupilsMenus, item.id, true);
      }
    },
    openPupils(ev, s) {
      s.x = ev.clientX - 200;
      s.y = ev.clientY;
      this.item = s;

      if (!s.pupils_popover_open) {
        Vue.set(this.pupilsEditMenus, s.id, false);
        Vue.set(this.pupilsMenus, s.id, true);
      }

      Vue.set(this.pupilsCloseMenus, s.id, false);
      setTimeout(() => {
        this.pupilsCloseMenus[s.id] = true;
      }, 1000);
    },
    closePupils(item, saved = false) {
      this.pupilsMenus[item.id] = false;
      if (this.pupilsEditMenus[item.id]) {
        const clone = JSON.parse(this.editingSession);
        this.pupilsEditMenus[item.id] = false;
        if (!saved) {
          item.nb_participants = clone.nb_participants;
        }
      }
    },
    fullname: item => item.firstname + " " + item.lastname,
    removeDate(item, i) {
      item.dates.splice(i, 1);
    },
    addDate(item) {
      item.dates.push({});
    },
    saveEvent(item, type) {
      const params = {};
      switch (type) {
        case "dates":
          for (let i = 0; i < item.dates.length; ++i) {
            const d = item.dates[i];

            d.start = d.date_start + " " + d.clock_start;
            d.end = d.date_start + " " + d.clock_end;

            item.dates[i] = d;

            item.presences[d.id] = {
              start: d.start,
              end: d.end,
              is: false,
              is_blocked: false
            };
          }

          params.dates = item.dates;
          params.presences = item.presences;
          break;
        case "class":
          params.class_name = item.class_name;
          params.room = item.room;
          params.referent_fullname = item.referent_fullname;
          params.referent_phone = item.referent_phone;
          params.lang_id = item.lang_id;
          break;
        case "tg":
          params.target_group_id = item.target_group_id;
          break;
        case "pupils":
          params.nb_participants = item.nb_participants;
          break;
        case "contacts":
          params.contact_id = item.contact_id;
          params.contact2_id = item.contact2_id;
          break;
      }

      /* let s = Object.assign({}, item)

      delete s.organization
      delete s.contact
      delete s.contact2 */

      Sessions.update(item.id, params).then(() => {
        this.$snotify.success("The session has been updated");
      });
    },
    validate(id, toSend = true) {
      Sessions.validate(id, { to_send: toSend }).then(() => {
        this.$snotify.success("The session has been upgraded");
        this.fetch();
        this.upgradeModal[id] = false;
      });
    },
    unvalidate(id, toSend = true) {
      Sessions.unvalidate(id, { to_send: toSend }).then(() => {
        this.$snotify.success("The session has been downgraded");
        this.fetch();
        this.downgradeModal[id] = false;
      });
    },
    isUpgradable(status, sess) {
      if (
        (status == Consts.RELEASE && !sess.trainers.length) ||
        status == Consts.CLOSED ||
        status == Consts.ONHOLD
      ) {
        return false;
      }
      if (!this.isAdmin && !this.isAppCoord(sess)) {
        return false;
      }
      return true;
    },

    exportCSV() {
      let params = Object.assign({}, this.pagination, this.filtersArray);
      Sessions.export(params).then(res => {
        let blob = new Blob([res.data], { type: "octet/stream" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(
            blob,
            moment().format("YYYY-MM-DD-hh-mm-ss") + ".csv"
          );
        } else {
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = moment().format("YYYY-MM-DD-hh-mm-ss") + ".csv";
          a.click();
          window.URL.revokeObjectURL(url);
        }
      });
    },

    print() {
      window.print();
    },

    isTrainerOnSession(session) {
      if ("trainers" in session) {
        let is = session.trainers
          .map(function(e) {
            return e.id;
          })
          .indexOf(Auth.user.id);

        if (is > -1) {
          return true;
        }
      }
      return false;
    },

    canBeTrainerEval(sess) {
      if (this.isAdmin || this.isAppCoord(sess)) {
        return true;
      } else if (
        sess.session_type != Consts.EVENTS &&
        this.isTrainerOnSession(sess) &&
        sess.status >= Consts.TOEVALUATE
      ) {
        if (sess.dates && sess.dates.length > 0) {
          if (moment(sess.dates[0].end).isBefore(moment())) {
            return true;
          }
          return false;
        }
        return false;
      }
      return false;
    },

    trainerEvalOpen(s) {
      if (this.isAdmin || this.isAppCoord(s)) {
        if (s.session_type == Consts.EVENTS) {
          this.$router.push({
            name: "sessions-edit-review",
            params: { id: s.id }
          });
        } else {
          this.$router.push({
            name: "sessions-show-trainer-evals",
            params: { id: s.id }
          });
        }
      } else {
        let trainer_id = null;
        let eva = null;
        this.editingSession = s;

        Sessions.get(s.id).then(res => {
          let session = res.data;
          if (session.trainers_presences) {
            let is = session.trainers_presences
              .map(function(e) {
                return e.user_id;
              })
              .indexOf(Auth.user.id);

            if (is > -1) {
              trainer_id = session.trainers_presences[is].id;
            }

            if (trainer_id) {
              Sessions.getTrainerEvals(s.id, {
                session_trainer_id: trainer_id
              }).then(res => {
                if (res.data && res.data[0]) {
                  eva = res.data[0];
                }
                if (eva) {
                  this.editTrainerEval(eva);
                } else {
                  this.newTrainerEval();
                }
              });
            }
          }
        });
      }
    },

    canBeReview(session) {
      if (!this.isAdmin && !this.isAppCoord(session)) {
        return false;
      }
      if (session.session_type != Consts.EVENTS) {
        return false;
      }
      return true;
    },

    isEvent(s) {
      if (s.session_type != Consts.EVENTS) {
        return false;
      }
      return true;
    },

    isInternal(s) {
      if (parseInt(s.session_type) == Consts.INTERNAL) {
        return true;
      }
      return false;
    },
    isTraining(s) {
      if (parseInt(s.session_type) == Consts.FREELANCES_TRAINING) {
        return true;
      }
      return false;
    },
    isActivity(s) {
      if (parseInt(s.session_type) == Consts.ACTIVITY) {
        return true;
      }
      return false;
    },

    blockSession(sess) {
      this.editingSession = sess;
      this.blockDialog = true;
    },

    evalSession(sess) {
      this.editingSession = sess;
      this.toEvalDialog = true;
    },

    canBeTeacherEval(session) {
      if (!this.isAdmin && !this.isAppCoord(session)) {
        return false;
      }
      if (session.session_type != Consts.SCHOOL) {
        return false;
      }
      return true;
    },

    loadUser() {
      if (Auth.user.is_admin) {
        this.isAdmin = true;
      }
      if (Auth.user.roles.indexOf(Consts.COORDINATION) > -1) {
        this.isCoord = true;
      }
      if (Auth.user.roles.indexOf(Consts.TRAINER) > -1) {
        this.isTrainer = true;
      }
    },

    joinSession(session) {
      this.editingSession = Object.assign({}, session);
      this.joinDialog = true;
    },

    onSelectMode(reset = false, init = false) {
      let json = JSON.parse(localStorage.getItem(this.storeKey));
      let json2 = JSON.parse(localStorage.getItem(this.storeKey2));
      if (!reset && json) {
        this.params = json;
        this.params.application = this.getStoredApp();

        if (json2) {
          this.pagination = json2;
        }
      } else {
        this.pagination.page = 1;
        this.params = {
          start: null,
          end: null,
          start_text: null,
          end_text: null,
          last_text: null,
          last: null,
          school: null,
          contact: null,
          address: null,
          event: null,
          comments: null,
          ticket_number: null,
          trainers: [],
          visitors: [],
          langs: [],
          status: [],
          session_type: [],
          target_group_id: [],
          team_id: [],
          topic_id: [],
          school_type_id: [],
          not_fully_eval: null,
          is_online: null,
          is_inclusive: null,
          reference: null,
          application: reset ? this.getDefaultApp() : this.getStoredApp(),
          onlyMySessions: false
        };
        this.keywords = null;
        this.fetchTopics();
      }

      if (!init) {
        switch (this.mode) {
          case 1: //To-dos
            this.params.status = [
              Consts.RELEASE,
              Consts.TOVALIDATE,
              Consts.BLOCKED,
              Consts.TOEVALUATE,
              Consts.DRAFT,
              Consts.CONFIRMED,
              Consts.ONHOLD
            ];
            break;
          case 2: //To-dos school training
            this.params.status = [
              Consts.RELEASE,
              Consts.TOVALIDATE,
              Consts.BLOCKED,
              Consts.TOEVALUATE,
              Consts.DRAFT,
              Consts.CONFIRMED,
              Consts.ONHOLD
            ];
            this.params.session_type = [Consts.SCHOOL];
            break;
          case 3: //Historic
            for (let i = 0; i < this.status.length; i++) {
              this.params.status.push(this.status[i].value);
            }
            break;
        }
      }

      this.fetch();
    },
    newTrainerEval() {
      this.trainerEvalDialog = true;
    },
    editTrainerEval(e) {
      this.editingTrainerEval = Object.assign({}, e);
      this.trainerEvalDialog = true;
    },
    stopClick() {}
  }
};
</script>

<style scoped>
.eval-block {
  background: #d6d6d6;
  width: 8px;
  height: 21px;
}

@media screen and (min-width: 1620px) {
  .dates-row {
    height: 26px;
  }
}
@media screen and (max-width: 1620px) {
  .dates-row {
    height: 39px;
  }
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
}

.toolbar-toggle {
  cursor: pointer;
  position: absolute;
  background: white;
  width: 40px;
  height: 16px;
  line-height: 0;
  text-align: center;
  left: calc(50% - 20px);
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select-mode > .v-input__control > .v-input__slot {
  width: fit-content;
}

.printable,
.printable-avatar {
  display: none;
}

@media print {
  .index-header,
  .index-footer,
  .no-printable,
  .v-datatable thead th.column.sortable i {
    display: none;
  }
  .printable {
    display: block;
  }
  .printable-avatar {
    display: inline-flex;
  }
  .bold-print {
    font-weight: bold;
  }

  @page {
    margin: 0;
    size: landscape;
    font-size: 12px;
  }
  body {
    height: 100%;
    width: 100%;
  }
  td,
  th {
    border: solid 1px #ccc;
    font-size: 12px;
  }

  .class-col {
    width: 25%;
  }
  .dates-col {
    width: 15%;
  }
  .contacts-col {
    width: 17%;
  }
  .avatar-col {
    width: 5%;
  }
  .orga-col {
    width: 17%;
  }
  .ref-col {
    width: 8%;
  }
  td,
  a {
    color: black;
    text-decoration: none;
  }
}
</style>
<style>
.dates-col {
  width: 200px;
}
.dates-col-trainer {
  width: 225px;
}
.id-col{
  width: 85px;
}
.search-input > .v-input__control > .v-input__slot > .v-input__append-inner {
  margin-top: 8px;
}
.v-datatable__actions__select,
.v-datatable__actions .v-btn {
  z-index: 7;
}

table.v-table {
  max-width: none;
}

@media print {
  @page {
    margin: 0;
    size: landscape;
    font-size: 12px;
  }
  .v-datatable__actions {
    display: none;
  }
  .sessions-table > .v-table__overflow > table {
    table-layout: fixed;
  }
  table.v-table tbody td {
    font-size: 12px;
  }
}

.sessions-table td,
.sessions-table th {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  height: unset;
}

.sessions-table > .v-table__overflow > table.v-table tbody td:first-child,
.sessions-table > .v-table__overflow > table.v-table tbody td:not(:first-child),
.sessions-table > .v-table__overflow > table.v-table tbody th:first-child,
.sessions-table > .v-table__overflow > table.v-table tbody th:not(:first-child),
.sessions-table > .v-table__overflow > table.v-table thead td:first-child,
.sessions-table > .v-table__overflow > table.v-table thead td:not(:first-child),
.sessions-table > .v-table__overflow > table.v-table thead th:first-child,
.sessions-table
  > .v-table__overflow
  > table.v-table
  thead
  th:not(:first-child) {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  height: unset;
}

.sessions-table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.sessions-table > .v-table__overflow {
  z-index: 50;
  overflow: visible;
}
</style>
